import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import FBAPrep from 'images/blog-images/fba-prep-fba-vs-fbm.png';
import EcommerceFulfillment from 'images/blog-images/ecommerce-fulfillment-fba-vs-fbm.png';
import FBAvsFBM from 'images/blog-images/fba-vs-fbm.png';
import FBAvsFBMFeatured from 'images/featured-image/fba-vs-fbm-featured.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query AmazonFBAFee {
      file(relativePath: { eq: "blog-amazon-fba-fee-compress.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="fba vs fbm: A full comparison for ecommerce sellers "/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="FBA vs FBM: A Full Comparison for eCommerce Sellers | Shipkoo"
        title="Amazon FBA or Shopify Fulfilment - Choose the Right Option for your Business! | Shipkoo"
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "FBA vs FBM: A Full Comparison for eCommerce Sellers | Shipkoo",
              content: `Amazon FBA or Shopify Fulfilment - Choose the Right Option for your Business! | Shipkoo`,
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: FBAvsFBMFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: "keywords",
              content: `Amazon FBA fee, Shopify Fulfilment`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="amazon-fba-fee"
        title={intl.formatMessage({ id: "amazon-fba-fee-title" })}
        date="2023-1-18"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
          <h5 className="no-top">
          		<strong>
              {intl.formatMessage({ id: "amazon-fba-fee-title" })}
          		</strong>
          	</h5>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content1" })}
               
            </p>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content2" })}
              
            </p>
          	<h5>
          		<strong>
              {intl.formatMessage({ id: "amazon-fba-fee-header2" })}
                 
          		</strong>
          	</h5>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content3" })}
               
            </p>
           <ul>
            <li style={{marginBottom:'20px'}}>
              {intl.formatMessage({ id: "amazon-fba-fee-content4" })}
               
            </li>
            <li style={{marginBottom:'20px'}}>
              {intl.formatMessage({ id: "amazon-fba-fee-content5" })}
                
            </li>
            <li>
              {intl.formatMessage({ id: "amazon-fba-fee-content6" })}
                
            </li>
           </ul>

            <h5>
              <strong>
              {intl.formatMessage({ id: "amazon-fba-fee-header3" })}
               
              </strong>
            </h5>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content7" })}
               
            </p>

            <h5>
              <strong>
              {intl.formatMessage({ id: "amazon-fba-fee-header4" })}
               
              </strong>
            </h5>    

            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content8" })}
               
            </p>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content9" })}
               
            </p>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content10" })}
                
            </p>

            <h5>
              <strong>
              {intl.formatMessage({ id: "amazon-fba-fee-header5" })}
               
              </strong>
            </h5>

            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content11" })}
                
            </p>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content12" })}
                
            </p>

            <h5>
              {intl.formatMessage({ id: "amazon-fba-fee-header6" })}
                
            </h5>

            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content13" })}
                
            </p>

            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content14" })}
                
            </p>

            <h5>
              {intl.formatMessage({ id: "amazon-fba-fee-header7" })}
                
            </h5>

            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content15" })}
                
            </p>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content16" })}
                
            </p>

            <h5>
              <strong>
              {intl.formatMessage({ id: "amazon-fba-fee-header8" })}
                
              </strong>
            </h5>

            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content17" })}
                
            </p>
            <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content18" })}
                
            </p>

            {/* <Image src={FBAPrep} alt="fba prep and forwarding" /> */}

            <BottomShare>
              <div className="column-half">
                <p>
              {intl.formatMessage({ id: "amazon-fba-fee-content19" })}</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>
          {intl.formatMessage({ id: "more-from-shipkoo-text" })}</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;